import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import '@/styles/index.scss'
import '@/styles/main.scss'
import 'element-ui/lib/theme-chalk/index.css';

ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI, {size:"medium"})
Vue.config.productionTip = false

import ZmTreeOrg from 'zm-tree-org/lib/zm-tree-org.umd.js';
import "zm-tree-org/lib/zm-tree-org.css";

Vue.use(ZmTreeOrg);


import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import router from './router'
import '@/permission'

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

Vue.directive('title', {
  inserted: function (el, binding) {
  document.title = "健康运动"
  }
})