<template>
  <div id="app">
    <!-- <router-view /> -->
    <keepAlive>
      <router-view v-if="$route.meta.keepAlive" />
    </keepAlive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
