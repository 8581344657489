import Vue from 'vue'
import Router from 'vue-router'
import { getToken } from "@/utils/auth";

const whiteList = ["/login"];



export const constantRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/main/index'),
    meta: { auth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: { auth: false },
  },
  {
    path: '/robot',
    component: () => import('@/views/main/robot'),
    meta: { auth: true }
  },
  {
    path: '/trouble',
    component: () => import('@/views/Trouble/index'),
    meta: { auth: true }
  },
  {
    path: '/trouble/list',
    component: () => import('@/views/Trouble/list'),
    meta: { auth: true }
  },
  {
    path: '/tree',
    component: () => import('@/views/Tree/index'),
    meta: { keepAlive: true, auth: true }
  },
  {
    path: '/planet',
    component: () => import('@/views/Planet/index'),
    meta: { auth: true }
  },
  {
    path: '/mobileChat',
    component: () => import('@/views/mobile/main'),
    meta: { auth: true }
  },
  {
    path: '/mobileTree',
    component: () => import('@/views/mobile/tree'),
    meta: { auth: true }
  }
]

const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

router.beforeEach(async (to, from, next) => {
  console.log(to.meta.auth);
  const hasToken = getToken();
  if (to.meta.auth) {
    if (hasToken == "undefined" || hasToken == undefined || hasToken == "") {
      if (whiteList.indexOf(to.path) !== -1 || to.path === "/error") {
        next();
      } else {
        next({ path: "/login" });
      }
    }else {
      next()
    }
  } else {
    console.log(to);
    next();
  }
});

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

Vue.use(Router)