import router from './router'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style


NProgress.configure({ showSpinner: false }) // NProgress Configuration


router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // if (to.path === '/main') {
    // if is logged in, redirect to the home page
    // next({ path: '/' })
  //   NProgress.done()
  // } else {
      next()
  // }
})

router.afterEach(() => {
  NProgress.done()
})
